
::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.creationCompetition {
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 30px 0 0 31px;
  overflow-y: auto;

  .creationCompetitionProgress {
    display: flex;
    .competitionProgress {
      margin: 54px auto;
      display: flex;
      align-items: center;
      .step-num {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        span:nth-child(1) {
          display: inline-block;
          width: 30px;
          height: 30px;
          font-size: 15px;
          color: #ffffff;
          background: #dcdcdc;
          border-radius: 50%;
          text-align: center;
          line-height: 30px;
        }
        .active {
          background: #1122d8 !important;
        }
        span:nth-child(2) {
          display: inline-block;
          width: 100px;
          text-align: center;
          top: 40px;

          position: absolute;
          font-size: 12px;
          color: #333333;
        }
      }
      .step-wire {
        height: 14px;
        margin: 0 10px;
      }
    }
  }
}
